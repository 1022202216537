<template>
  <div class="home">
    <form id="alustaForm">
      <div class="row p-2 m-2">
        <div class="col-xl-8">
          <div class="hideOnScreen p-5 row">
            <div class="col-2"><LogoScreen /></div>
          </div>
          <DrawScreen />
        </div>
        <div class="col-xl-4 text-start m-0 p-0 hideOnPrint">
          <div class="m-5 text-center">
            <LocalChange />
            <LogoScreen />
          </div>
          <div class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3">
            <MainTitle msg="MainTitle.Deuren/panelen" />
            <div>
              <SubTitle msg="SubTitle.Producttype" />
              <BigButton
                :src="require('@/assets/EnkeleHydraulischeDeur.png')"
                msg="Producttype.Hydraulische"
                :selected="this.$store.state.productType == 1"
                v-on:click.prevent="selectProductType(1, 1)"
              />
              <BigButton
                :src="require('@/assets/EnkelVastPaneel.png')"
                msg="Producttype.Paneel"
                :selected="this.$store.state.productType == 0"
                v-on:click.prevent="selectProductType(0, 0)"
              />
              <BigButton
                :src="require('@/assets/Schuifdeur.png')"
                msg="Producttype.Sliding"
                :selected="this.$store.state.productType == 2"
                v-on:click.prevent="selectProductType(2, 1)"
                v-if="Token.substring(0, 3) === 'EGD'"
              />
              <BaseInfo :msg="$t('Producttype.Info01')" />
            </div>
            <div v-if="this.$store.state.aantalDeuren != 0">
              <SubTitle msg="SubTitle.Deurtype" />
              <div v-if="this.$store.state.productType == 1">
                <BigButton
                  :src="require('@/assets/EnkeleDeur.png')"
                  msg="Deurtype.Enkel"
                  :selected="this.$store.state.aantalDeuren == 1"
                  v-on:click.prevent="selectAantalDeuren(1)"
                />
                <BigButton
                  :src="require('@/assets/DubbeleDeur.png')"
                  msg="Deurtype.Dubbel"
                  :selected="this.$store.state.aantalDeuren == 2"
                  v-on:click.prevent="selectAantalDeuren(2)"
                />
              </div>
              <div v-if="this.$store.state.productType == 2">
                <BigButton
                  :src="require('@/assets/EnkeleSchuifdeur.png')"
                  msg="Deurtype.Enkel"
                  :selected="this.$store.state.aantalDeuren == 1"
                  v-on:click.prevent="selectAantalDeuren(1)"
                />
                <BigButton
                  :src="require('@/assets/DubbeleSchuifdeur.png')"
                  msg="Deurtype.Dubbel"
                  :selected="this.$store.state.aantalDeuren == 2"
                  v-on:click.prevent="selectAantalDeuren(2)"
                />
              </div>
            </div>

            <div v-if="productType == 1 && aantalDeuren == 1">
              <SubTitle msg="SubTitle.Draairichting" />
              <BigButton
                :src="require('@/assets/DraairichtingLinks.png')"
                msg="Base.Links"
                :selected="this.$store.state.draairichting == 0"
                v-on:click.prevent="selectDraairichting(0)"
              />
              <BigButton
                :src="require('@/assets/DraairichtingRechts.png')"
                msg="Base.Rechts"
                :selected="this.$store.state.draairichting == 1"
                v-on:click.prevent="selectDraairichting(1)"
              />
            </div>

            <!-- DEURMATEN -->
            <SubTitle
              msg="SubTitle.Deurmaten"
              :showQuestion="true"
              helpProp="deurmaten"
            />
            <div class="m-0 p-0 row g-0 align-items-end mb-3">
              <div class="col-6">
                <div class="m-0 p-0 row g-0 align-items-end">
                  <div class="col-4">
                    <img src="@/assets/Deurbreedte.png" style="height: 150px" />
                  </div>
                  <div class="col-8">
                    <h3 class="mb-3">{{ $t("Deurmaten.Breedte") }}</h3>
                    <div class="m-0 p-0 row g-0 align-items-center">
                      <div class="col-9">
                        <input
                          type="number"
                          id="Bkader"
                          class="form-control rounded-pill"
                          v-model="Bkader"
                          :class="[
                            Bkader > 1500 ||
                            Bkader < 100 ||
                            $store.state.gewicht > 100
                              ? 'is-invalid'
                              : '',
                          ]"
                        />
                      </div>
                      <div class="col-3">mm</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="m-0 p-0 row g-0 align-items-end">
                  <div class="col-4 p-0 m-0">
                    <img src="@/assets/Deurhoogte.png" style="height: 150px" />
                  </div>
                  <div class="col-8">
                    <h3 class="mb-3">{{ $t("Deurmaten.Hoogte") }}</h3>
                    <div class="m-0 p-0 row g-0 align-items-center">
                      <div class="col-9">
                        <input
                          type="number"
                          id="Hkader"
                          class="form-control rounded-pill"
                          v-model="Hkader"
                          :class="[
                            Hkader > 3000 ||
                            Hkader < 100 ||
                            $store.state.gewicht > 100
                              ? 'is-invalid'
                              : '',
                          ]"
                        />
                      </div>
                      <div class="col-3">mm</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <BaseInfo
              :msg="
                $t('Deurmaten.Info01', [
                  this.$store.state.spanmaat.Breedte,
                  this.$store.state.spanmaat.Hoogte,
                ])
              "
            />
            <BaseInfo
              :msg="$t('Deurmaten.Error01')"
              :error="true"
              v-if="Hkader > 3000"
            />
            <BaseInfo
              :msg="$t('Deurmaten.Error02')"
              :error="true"
              v-if="Bkader > 1500"
            />
            <BaseInfo
              :msg="$t('Deurmaten.Error03')"
              :error="true"
              v-if="$store.state.gewicht > 100"
            />
            <HelpDeurmaten v-if="$store.state.helpProperty.deurmaten" />
            <AlTools />
            <!-- END DEURMATEN -->

            <!-- Zijspeling -->
            <div v-if="productType == 2">
              <SubTitle msg="SubTitle.Zijspeling" />
              <div class="mx-3 mb-3">
                <label for="zijspeling">{{ $t("Zijspeling.Afstand") }}</label>
                <input
                  type="number"
                  id="zijspeling"
                  class="form-control rounded-pill"
                  v-model="schuifdeurZijspeling"
                />
              </div>
            </div>

            <!-- END Zijspeling -->
            <!-- PANELEN -->
            <SubTitle msg="SubTitle.Panelen" />
            <div class="row g-0 mx-3 mb-3">
              <div class="col-6 p-3 border-end">
                <img src="@/assets/PanelenLinks.png" style="height: 150px" />
                <strong>{{ $t("Base.Links") }}</strong>
                <div class="text-center mb-3">
                  <NumButton
                    msg="0"
                    :selected="this.$store.state.aantalPanelenLinks == 0"
                    v-on:click.prevent="setAantalPanelenLinks(0)"
                  />
                  <NumButton
                    msg="1"
                    :selected="this.$store.state.aantalPanelenLinks == 1"
                    v-on:click.prevent="setAantalPanelenLinks(1)"
                  />
                  <NumButton
                    msg="2"
                    :selected="this.$store.state.aantalPanelenLinks == 2"
                    v-on:click.prevent="setAantalPanelenLinks(2)"
                  />
                  <NumButton
                    msg="3"
                    :selected="this.$store.state.aantalPanelenLinks == 3"
                    v-on:click.prevent="setAantalPanelenLinks(3)"
                  />
                </div>
                <div
                  class="mb-3"
                  v-for="(item, index) in $store.state.panelenLinks"
                  :key="index"
                >
                  <label>{{ $t("Panelen.PaneelNum", [index + 1]) }}</label>
                  <input
                    type="number"
                    class="form-control rounded-pill"
                    :value="$store.state.panelenLinks[index]"
                    v-on:input="updatePaneelLinks($event, index)"
                    :class="[
                      $store.state.panelenLinks[index] > 1500
                        ? 'is-invalid'
                        : '',
                    ]"
                  />
                  <div class="invalid-feedback">
                    {{ $t("Panelen.Error01") }}
                  </div>
                </div>
              </div>
              <div class="col-6 p-3">
                <img src="@/assets/PanelenRechts.png" style="height: 150px" />
                <strong>{{ $t("Base.Rechts") }}</strong>
                <div class="text-center mb-3">
                  <NumButton
                    msg="0"
                    :selected="this.$store.state.aantalPanelenRechts == 0"
                    v-on:click.prevent="setAantalPanelenRechts(0)"
                  />
                  <NumButton
                    msg="1"
                    :selected="this.$store.state.aantalPanelenRechts == 1"
                    v-on:click.prevent="setAantalPanelenRechts(1)"
                  />
                  <NumButton
                    msg="2"
                    :selected="this.$store.state.aantalPanelenRechts == 2"
                    v-on:click.prevent="setAantalPanelenRechts(2)"
                  />
                  <NumButton
                    msg="3"
                    :selected="this.$store.state.aantalPanelenRechts == 3"
                    v-on:click.prevent="setAantalPanelenRechts(3)"
                  />
                </div>

                <div
                  class="mb-3"
                  v-for="(item, index) in $store.state.panelenRechts"
                  :key="index"
                >
                  <label>{{ $t("Panelen.PaneelNum", [index + 1]) }}</label>
                  <input
                    type="number"
                    class="form-control rounded-pill"
                    :value="$store.state.panelenRechts[index]"
                    v-on:input="updatePaneelRechts($event, index)"
                    :class="[
                      $store.state.panelenRechts[index] > 1500
                        ? 'is-invalid'
                        : '',
                    ]"
                  />
                  <div class="invalid-feedback">
                    {{ $t("Panelen.Error01") }}
                  </div>
                </div>
              </div>
            </div>

            <!-- END PANELEN -->

            <!-- Draaipunt -->
            <div v-if="productType == 1">
              <SubTitle
                msg="SubTitle.Draaipunt"
                :showQuestion="true"
                helpProp="draaipunt"
              />
              <div class="mx-3 mb-3">
                <label for="draaipuntX">{{ $t("Draaipunt.Afstand") }}</label>
                <input
                  type="number"
                  id="draaipuntX"
                  class="form-control rounded-pill"
                  v-model="draaipuntX"
                  :class="[
                    draaipuntX > Bkader / 2 || draaipuntX < 110
                      ? 'is-invalid'
                      : '',
                  ]"
                />
              </div>
              <BaseInfo :msg="$t('Draaipunt.Info01')" />
              <BaseInfo
                :msg="$t('Draaipunt.Error01')"
                :error="true"
                v-if="this.$store.state.toonDraaipuntWaarschuwing"
              />
              <BaseInfo
                :msg="$t('Draaipunt.Error02', [Bkader / 2])"
                :error="true"
                v-if="draaipuntX > Bkader / 2"
              />
              <BaseInfo
                :msg="$t('Draaipunt.Error03')"
                :error="true"
                v-if="draaipuntX < 110"
              />
              <HelpDraaipunt v-if="$store.state.helpProperty.draaipunt" />
            </div>

            <!-- END Draaipunt -->
          </div>
          <!-- END DEUREN / PANELEN -->

          <!-- KADERVERDELING //-->
          <div
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
          >
            <MainTitle msg="MainTitle.Kaderverdeling" />
            <SubTitle
              msg="SubTitle.Aantal/positie"
              :showQuestion="true"
              helpProp="onderverdeling"
            />
            <div class="row g-0 mx-3 mb-3">
              <div class="col-6 p-3 border-end">
                <img src="@/assets/Verticaal.png" style="height: 150px" />
                <strong>{{ $t("Base.Verticaal") }}</strong>
                <div class="mb-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="asymmetrisch"
                    />
                    <label class="form-check-label">
                      {{ $t("Panelen.Asymmetrisch") }}
                    </label>
                  </div>
                </div>
                <div class="text-center mb-3">
                  <NumButton
                    msg="0"
                    :selected="this.verticaleOnderverdeling == 0"
                    v-on:click.prevent="setAantalVerticaleOnderverdeling(0)"
                  />
                  <NumButton
                    msg="1"
                    :selected="this.verticaleOnderverdeling == 1"
                    v-on:click.prevent="setAantalVerticaleOnderverdeling(1)"
                  />
                  <NumButton
                    msg="2"
                    :selected="this.verticaleOnderverdeling == 2"
                    v-on:click.prevent="setAantalVerticaleOnderverdeling(2)"
                  />
                </div>
                <!-- dividers aanpassen -->
                <div v-if="asymmetrisch">
                  <div
                    class="mb-3"
                    v-for="(item, index) in $store.state
                      .verticaleOnderverdelingen"
                    :key="index"
                  >
                    <label>
                      {{ $t("Base.Verticaal") }} {{ index + 1 }} (mm)</label
                    >
                    <input
                      type="number"
                      class="form-control rounded-pill"
                      :value="$store.state.verticaleOnderverdelingen[index]"
                      v-on:input="updateVerticaleOnderverdeling($event, index)"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 p-3">
                <img src="@/assets/Horizontaal.png" style="height: 150px" />
                <strong>{{ $t("Base.Horizontaal") }}</strong>
                <div class="mb-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="asymmetrisch"
                    />
                    <label class="form-check-label">
                      {{ $t("Panelen.Asymmetrisch") }}
                    </label>
                  </div>
                </div>
                <div class="text-center mb-3">
                  <NumButton
                    msg="0"
                    :selected="this.horizontaleOnderverdeling == 0"
                    v-on:click.prevent="setAantalHorizontaleOnderverdeling(0)"
                  />
                  <NumButton
                    msg="1"
                    :selected="this.horizontaleOnderverdeling == 1"
                    v-on:click.prevent="setAantalHorizontaleOnderverdeling(1)"
                  />
                  <NumButton
                    msg="2"
                    :selected="this.horizontaleOnderverdeling == 2"
                    v-on:click.prevent="setAantalHorizontaleOnderverdeling(2)"
                  />
                  <NumButton
                    msg="3"
                    :selected="this.horizontaleOnderverdeling == 3"
                    v-on:click.prevent="setAantalHorizontaleOnderverdeling(3)"
                  />
                </div>
                <!-- dividers aanpassen -->
                <div v-if="asymmetrisch">
                  <div
                    class="mb-3"
                    v-for="(item, index) in $store.state
                      .horizontaleOnderverdelingen"
                    :key="index"
                  >
                    <label>
                      {{ $t("Base.Horizontaal") }} {{ index + 1 }} (mm)</label
                    >
                    <input
                      type="number"
                      class="form-control rounded-pill"
                      :value="$store.state.horizontaleOnderverdelingen[index]"
                      v-on:input="
                        updateHorizontaleOnderverdeling($event, index)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <HelpOnderverdeling
              v-if="$store.state.helpProperty.onderverdeling"
            />
            <div
              v-if="
                horizontaleOnderverdeling > 0 || verticaleOnderverdeling > 0
              "
            >
              <SubTitle msg="SubTitle.ALOType" />
              <BigButton
                :src="require('@/assets/TProfiel.png')"
                msg="Panelen.TProfiel"
                :selected="this.$store.state.ALOkeuze == 0"
                v-on:click.prevent="setALOkeuze(0)"
              />
              <BigButton
                :src="require('@/assets/Profiel.png')"
                msg="Panelen.IProfiel"
                :selected="this.$store.state.ALOkeuze == 1"
                v-on:click.prevent="setALOkeuze(1)"
              />

              <BaseInfo :msg="$t('Panelen.ProfielInfo')" />
            </div>
          </div>
          <!-- END KADERVERDELING //-->
          <!-- DEURGREPEN //-->
          <div
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
            v-if="aantalDeuren > 0"
          >
            <MainTitle msg="MainTitle.Deurgrepen" />
            <div>
              <SubTitle msg="SubTitle.Greeptype" />
              <BigButton
                :src="require('@/assets/Hoekgreep.png')"
                msg="Greeptype.Hoekgreep"
                :selected="
                  this.$store.state.hendelKeuze == 0 ||
                  (this.$store.state.hendelKeuze >= 9 &&
                    this.$store.state.hendelKeuze <= 13)
                "
                v-on:click.prevent="setHendelKeuze(0)"
              />
              <BigButton
                :src="require('@/assets/AMURSQTrekker.png')"
                msg="Greeptype.AMURSQ"
                :selected="
                  this.$store.state.hendelKeuze >= 5 &&
                  this.$store.state.hendelKeuze <= 8
                "
                v-on:click.prevent="setHendelKeuze(5)"
              />
              <BigButton
                :src="require('@/assets/AMTTrekker.png')"
                msg="Greeptype.AMT"
                :selected="
                  this.$store.state.hendelKeuze >= 1 &&
                  this.$store.state.hendelKeuze <= 4
                "
                v-on:click.prevent="setHendelKeuze(1)"
              />
            </div>

            <div
              v-if="hendelKeuze == 0 || (hendelKeuze >= 9 && hendelKeuze <= 14)"
            >
              <SubTitle msg="SubTitle.Maten" />
              <div class="row align-items-center">
                <div class="col-6 text-center">
                  <img
                    src="@/assets/HoekgreepMaten.png"
                    style="height: 250px"
                  />
                </div>
                <div class="col-6">
                  <p>
                    <NumButton
                      msg="d=25, a=100, l=150, b=35.5"
                      :selected="this.hendelKeuze == 0"
                      v-on:click.prevent="setHendelKeuze(0)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=25, a=100, l=300, b=35.5"
                      :selected="this.hendelKeuze == 9"
                      v-on:click.prevent="setHendelKeuze(9)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=25, a=100, l=500, b=35.5"
                      :selected="this.hendelKeuze == 10"
                      v-on:click.prevent="setHendelKeuze(10)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=25, a=100, l=1200, b=35.5"
                      :selected="this.hendelKeuze == 11"
                      v-on:click.prevent="setHendelKeuze(11)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=25, a=100, l=2000, b=35.5"
                      :selected="this.hendelKeuze == 12"
                      v-on:click.prevent="setHendelKeuze(12)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=25, a=100, l=?, b=35.5"
                      :selected="this.hendelKeuze == 13"
                      v-on:click.prevent="setHendelKeuze(13)"
                    />
                  </p>
                  <div v-if="hendelKeuze == 13">
                    <label for="greeplengte">{{
                      $t("Base.greeplengte")
                    }}</label>
                    <input
                      type="number"
                      id="greeplengte"
                      class="form-control rounded-pill"
                      v-model="greeplengte"
                      :class="[greeplengte < 10 ? 'is-invalid' : '']"
                    />
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-6 text-center">
                  <img
                    src="@/assets/MiddenslotMetHoekgreepConfiguratorMaten-01.png"
                    style="height: 250px"
                  />
                </div>
                <div class="col-6">
                  <h2 class="font-egdl fw-bold fs-6">
                    {{ $t("SubTitle.MetMiddenslot") }}
                  </h2>
                  <p>
                    <NumButton
                      msg="d=30, a=70, l=100, b=35.5"
                      :selected="this.hendelKeuze == 14"
                      v-on:click.prevent="setHendelKeuze(14)"
                    />
                  </p>
                </div>
              </div>
            </div>

            <div v-if="hendelKeuze >= 1 && hendelKeuze <= 4">
              <SubTitle msg="SubTitle.Maten" />
              <div class="row align-items-center">
                <div class="col-6 text-center">
                  <img src="@/assets/AMTMaten.png" style="height: 250px" />
                </div>
                <div class="col-6">
                  <p>
                    <NumButton
                      msg="d=20, a=200, l=300"
                      :selected="this.hendelKeuze == 1"
                      v-on:click.prevent="setHendelKeuze(1)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=20, a=340, l=500"
                      :selected="this.hendelKeuze == 2"
                      v-on:click.prevent="setHendelKeuze(2)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=20, a=540, l=700"
                      :selected="this.hendelKeuze == 3"
                      v-on:click.prevent="setHendelKeuze(3)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=20, a=1040, l=1200"
                      :selected="this.hendelKeuze == 4"
                      v-on:click.prevent="setHendelKeuze(4)"
                    />
                  </p>
                </div>
              </div>
            </div>

            <div v-if="hendelKeuze >= 5 && hendelKeuze <= 8">
              <SubTitle msg="SubTitle.Maten" />
              <div class="row align-items-center">
                <div class="col-6 text-center">
                  <img src="@/assets/AMURSQMaten.png" style="height: 250px" />
                </div>
                <div class="col-6">
                  <p>
                    <NumButton
                      msg="d=20, a=20, l=300"
                      :selected="this.hendelKeuze == 5"
                      v-on:click.prevent="setHendelKeuze(5)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=20, a=20, l=500"
                      :selected="this.hendelKeuze == 6"
                      v-on:click.prevent="setHendelKeuze(6)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=20, a=20, l=1200"
                      :selected="this.hendelKeuze == 7"
                      v-on:click.prevent="setHendelKeuze(7)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=30, a=30, l=2000"
                      :selected="this.hendelKeuze == 8"
                      v-on:click.prevent="setHendelKeuze(8)"
                    />
                  </p>
                </div>
              </div>
              <BaseInfo
                :msg="$t('Greeptype.Error01')"
                :error="true"
                v-if="$store.state.hendel[hendelKeuze].p_height > Hkader"
              />
            </div>
            <SubTitle
              msg="SubTitle.Positie"
              :showQuestion="true"
              helpProp="deurgrepen"
            />
            <div class="row mx-3 mb-3">
              <div class="col-6">
                <label for="hendelPositieY">{{ $t("Base.Verticaal") }}</label>
                <input
                  type="number"
                  id="hendelPositieY"
                  class="form-control rounded-pill"
                  v-model="hendelPositieY"
                  :class="[
                    hendelPositieY <
                      $store.state.hendel[hendelKeuze].p_height / 2 ||
                    hendelPositieY >
                      $store.state.kadermaat.Hoogte -
                        $store.state.hendel[hendelKeuze].p_height / 2
                      ? 'is-invalid'
                      : '',
                  ]"
                />
              </div>
              <div class="col-6" v-if="hendelKeuze != 0 && hendelKeuze < 9">
                <label for="hendelPositieX">{{ $t("Base.Horizontaal") }}</label>
                <input
                  type="number"
                  id="hendelPositieX"
                  class="form-control rounded-pill"
                  v-model="hendelPositieX"
                />
              </div>
            </div>
            <HelpDeurgrepen v-if="$store.state.helpProperty.deurgrepen" />

            <!--  SLOT -->
            <div v-if="hendelKeuze != 0 && hendelKeuze < 9">
              <SubTitle
                msg="SubTitle.MetMiddenslot"
                :showQuestion="true"
                helpProp="trekkermetslot"
              />
              <BigButton
                :src="require('@/assets/AS522.png')"
                msg="Slot.1Middenslot"
                :selected="this.middenslot == 1"
                v-on:click.prevent="setMiddenslot(1)"
              />
              <BigButton
                :src="require('@/assets/GeenSlot.png')"
                msg="Slot.0Middenslot"
                :selected="this.middenslot == 0"
                v-on:click.prevent="setMiddenslot(0)"
              />
            </div>
            <HelpTrekkerMetSlot
              v-if="
                $store.state.helpProperty.trekkermetslot &&
                hendelKeuze != 0 &&
                hendelKeuze < 9
              "
            />
            <div v-if="this.middenslot == 1">
              <SubTitle msg="SubTitle.Slotplaat" />
              <BigButton
                :src="require('@/assets/V532.png')"
                msg="Slot.1Slotplaat"
                :selected="this.slotplaat == 1"
                v-on:click.prevent="setSlotplaat(1)"
              />
              <BigButton
                :src="require('@/assets/GeenSlot.png')"
                msg="Slot.0Slotplaat"
                :selected="this.slotplaat == 0"
                v-on:click.prevent="setSlotplaat(0)"
              />
              <!--Cilinders-->
              <SubTitle msg="SubTitle.Cilinders" />
              <BigButton
                :src="require('@/assets/V017.E.png')"
                msg="Slot.0CilinderKleur"
                :selected="this.cilinderkleur == 0"
                v-on:click.prevent="setCilinderKleur(0)"
              />
              <BigButton
                :src="require('@/assets/V017.G.png')"
                msg="Slot.1CilinderKleur"
                :selected="this.cilinderkleur == 1"
                v-on:click.prevent="setCilinderKleur(1)"
              />
              <br />
              <BigButton
                :src="require('@/assets/V017S.png')"
                msg="Slot.0CilinderType"
                :selected="this.cilindertype == 0"
                v-on:click.prevent="setCilinderType(0)"
              />
              <BigButton
                :src="require('@/assets/V017D.png')"
                msg="Slot.1CilinderType"
                :selected="this.cilindertype == 1"
                v-on:click.prevent="setCilinderType(1)"
              />
            </div>
            <!-- EINDE SLOT-->
          </div>
          <!-- END DEURGREPEN //-->
          <!-- GLAS //-->
          <div
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
          >
            <MainTitle msg="MainTitle.Glas" />
            <SubTitle msg="SubTitle.Glassoorten" />
            <BigButton
              v-for="(model, index) in this.$store.state.glassoort"
              v-bind:key="index"
              :src="require('@/assets/Glassoorten/' + model.img)"
              :msg="model.title"
              :selected="this.$store.state.glassoortKeuze == index"
              v-on:click.prevent="setGlassoortKeuze(index)"
            />
            <BaseInfo :msg="$t('Error.glasstock')" :error="true" />
          </div>
          <!-- END GLAS //-->

          <!-- EXTRA //-->
          <div
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
            v-if="horizontaleOnderverdeling > 0 || aantalDeuren > 0"
          >
            <MainTitle msg="MainTitle.Extra" />
            <div v-if="horizontaleOnderverdeling > 0 && ALOkeuze != 1">
              <SubTitle msg="SubTitle.Opvulvlak" />
              <BigButton
                :src="require('@/assets/Opvulvlak.png')"
                msg="Opvulvlak.1Opvulvlak"
                :selected="this.opvulvlak == 1"
                v-on:click.prevent="setOpvulvlak(1)"
              />
              <BigButton
                :src="require('@/assets/GeenSlot.png')"
                msg="Opvulvlak.0Opvulvlak"
                :selected="this.opvulvlak == 0"
                v-on:click.prevent="setOpvulvlak(0)"
              />
              <BaseInfo :msg="$t('Opvulvlak.Info01')" />
            </div>
            <!--  SLOT -->
            <div v-if="aantalDeuren > 0">
              <SubTitle msg="SubTitle.Deurslot" />
              <BigButton
                :src="require('@/assets/ASC01.png')"
                msg="Slot.1Slot"
                :selected="this.slot == 1"
                v-on:click.prevent="setSlot(1)"
              />
              <BigButton
                :src="require('@/assets/GeenSlot.png')"
                msg="Slot.0Slot"
                :selected="this.slot == 0"
                v-on:click.prevent="setSlot(0)"
              />
              <BaseInfo :msg="$t('Slot.Info01')" />
              <div v-if="this.slot == 1">
                <!-- SLOT POT-->
                <SubTitle msg="SubTitle.SlotPot" />
                <BigButton
                  :src="require('@/assets/V515.png')"
                  msg="Slot.1SlotPot"
                  :selected="this.slotpot == 1"
                  v-on:click.prevent="setSlotPot(1)"
                />
                <BigButton
                  :src="require('@/assets/GeenSlot.png')"
                  msg="Slot.0SlotPot"
                  :selected="this.slotpot == 0"
                  v-on:click.prevent="setSlotPot(0)"
                />
                <!--Cilinders-->
                <SubTitle msg="SubTitle.Cilinders" />
                <BigButton
                  :src="require('@/assets/V017.E.png')"
                  msg="Slot.0CilinderKleur"
                  :selected="this.cilinderkleur == 0"
                  v-on:click.prevent="setCilinderKleur(0)"
                />
                <BigButton
                  :src="require('@/assets/V017.G.png')"
                  msg="Slot.1CilinderKleur"
                  :selected="this.cilinderkleur == 1"
                  v-on:click.prevent="setCilinderKleur(1)"
                />
                <br />
                <BigButton
                  :src="require('@/assets/V017S.png')"
                  msg="Slot.0CilinderType"
                  :selected="this.cilindertype == 0"
                  v-on:click.prevent="setCilinderType(0)"
                />
                <BigButton
                  :src="require('@/assets/V017D.png')"
                  msg="Slot.1CilinderType"
                  :selected="this.cilindertype == 1"
                  v-on:click.prevent="setCilinderType(1)"
                />
              </div>
            </div>
            <!-- EINDE SLOT-->
            <!-- Kantschuifslot-->
            <div v-if="productType == 1">
              <SubTitle msg="SubTitle.Kantschuifslot" />
              <BigButton
                :src="require('@/assets/Kantschuifmatzwart.png')"
                msg="Slot.1Kantschuifslot"
                :selected="this.kantschuifslot == 1"
                v-on:click.prevent="setKantschuifslot(1)"
              />
              <BigButton
                :src="require('@/assets/Kantschuifchrome.png')"
                msg="Slot.2Kantschuifslot"
                :selected="this.kantschuifslot == 2"
                v-on:click.prevent="setKantschuifslot(2)"
              />
              <BigButton
                :src="require('@/assets/GeenSlot.png')"
                msg="Slot.0Kantschuifslot"
                :selected="this.kantschuifslot == 0"
                v-on:click.prevent="setKantschuifslot(0)"
              />
            </div>
            <!-- EINDE Kantschuifslot-->
            <!--<div class="mx-3 mb-3" v-if="$store.state.slot == 1">
              <label>{{ $t("Slot.SlotX") }}</label>
              <input
                type="number"
                class="form-control rounded-pill"
                v-model="slotX"
              />
            </div>//-->
          </div>
          <!-- END EXTRA //-->
          <ColorSelect />
          <div
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
          >
            <MainTitle msg="Referenties.MainTitle" />
            <SubTitle msg="" />
            <div class="m-3" v-if="$store.state.orderID == 0">
              <label for="klantRef">{{ $t("Referenties.KlantRef") }}</label>
              <input
                id="klantRef"
                class="form-control rounded-pill"
                v-model="klantRef"
              />
            </div>
            <div class="m-3">
              <label for="referentie">{{ $t("Referenties.Referentie") }}</label>
              <input
                id="referentie"
                class="form-control rounded-pill"
                v-model="deurgeheelRef"
              />
            </div>
          </div>
          <PriceCalcScreen />
          <!-- QOUTE FORM //-->
          <div
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
            v-if="Token == 'INVALID'"
          >
            <MainTitle
              msg="OfferteFormulier.MainTitle"
              v-if="$store.getters.getCustomizationNumber != 4"
            />
            <MainTitle
              msg="OfferteFormulier.MainTitleVerdouw"
              v-if="$store.getters.getCustomizationNumber == 4"
            />
            <SubTitle
              msg="OfferteFormulier.SubTitle"
              v-if="$store.getters.getCustomizationNumber != 4"
            />
            <SubTitle
              msg=""
              v-if="$store.getters.getCustomizationNumber == 4"
            />
            <div v-if="qouteFormSubmitted">
              <BaseInfo :msg="$t('OfferteFormulier.bedankt')" />
            </div>
            <form
              id="qouteForm"
              @submit.prevent="sendMail"
              v-if="!qouteFormSubmitted"
            >
              <div class="m-3">
                <label for="qoute_firstname">{{
                  $t("OfferteFormulier.voornaam")
                }}</label>
                <input
                  id="qoute_firstname"
                  class="form-control rounded-pill"
                  v-model="qoute_firstname"
                  required
                />
              </div>
              <div class="m-3">
                <label for="qoute_lastname">{{
                  $t("OfferteFormulier.naam")
                }}</label>
                <input
                  id="qoute_lastname"
                  class="form-control rounded-pill"
                  v-model="qoute_lastname"
                  required
                />
              </div>
              <div class="m-3">
                <label for="qoute_phone">{{
                  $t("OfferteFormulier.tel")
                }}</label>
                <input
                  type="tel"
                  id="qoute_phone"
                  class="form-control rounded-pill"
                  v-model="qoute_phone"
                  pattern="(\+){0,1}[0-9\s]{5,}"
                  required
                />
              </div>
              <div class="m-3">
                <label for="qoute_mail">{{
                  $t("OfferteFormulier.email")
                }}</label>
                <input
                  type="email"
                  id="qoute_mail"
                  class="form-control rounded-pill"
                  v-model="qoute_mail"
                  required
                />
              </div>
              <div class="m-3">
                <label for="qoute_street">{{
                  $t("OfferteFormulier.straat")
                }}</label>
                <input
                  id="qoute_street"
                  class="form-control rounded-pill"
                  v-model="qoute_street"
                  required
                />
              </div>
              <div class="m-3">
                <label for="qoute_zip">{{
                  $t("OfferteFormulier.postcode")
                }}</label>
                <input
                  id="qoute_zip"
                  class="form-control rounded-pill"
                  v-model="qoute_zip"
                  required
                />
              </div>
              <div class="m-3">
                <label for="qoute_municipality">
                  {{ $t("OfferteFormulier.gemeente") }}
                </label>
                <input
                  id="qoute_municipality"
                  class="form-control rounded-pill"
                  v-model="qoute_municipality"
                  required
                />
              </div>
              <div
                class="m-3"
                v-if="$store.getters.getCustomizationNumber != 4"
              >
                <label for="qoute_country">{{
                  $t("OfferteFormulier.land")
                }}</label>
                <input
                  id="qoute_country"
                  class="form-control rounded-pill"
                  v-model="qoute_country"
                  required
                />
              </div>
              <div
                class="m-3"
                v-if="$store.getters.getCustomizationNumber != 4"
              >
                <label for="qoute_country">{{
                  $t("OfferteFormulier.welkeDealer")
                }}</label>
                <input
                  id="qoute_dealer"
                  class="form-control rounded-pill"
                  v-model="qoute_dealer"
                  required
                />
              </div>
              <div class="m-3 d-grid border border-primary rounded-pill p-2">
                <button
                  type="submit"
                  class="btn btn-white border-light shadow-sm rounded-pill font-egdl"
                >
                  {{
                    $store.getters.getCustomizationNumber != 4
                      ? $t("Knoppen.Mail")
                      : $t("Knoppen.MailVerdouw")
                  }}
                </button>
              </div>
            </form>
          </div>
          <!-- END QOUTE FORM//-->
          <div class="row g-3 m-3">
            <div class="col-6" v-if="Token != 'INVALID'">
              <div class="d-grid border border-primary rounded-pill p-2">
                <button
                  class="btn btn-white border-light shadow-sm rounded-pill font-egdl"
                  v-on:click.prevent="toEGDesign"
                >
                  {{ $t("Knoppen.Opslaan") }}
                </button>
              </div>
            </div>
            <div class="col-6" v-if="Token != 'INVALID'">
              <div class="d-grid border border-primary rounded-pill p-2">
                <button
                  class="btn btn-white border-light shadow-sm rounded-pill font-egdl"
                  v-on:click.prevent="backToEgdl"
                >
                  {{ $t("Knoppen.Terug") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- END col-6 //-->
      </div>
    </form>
    <LoginScreen />
    <PrintScreen />
    <div style="display: none">{{ $store.state.version }}</div>
  </div>
</template>

<script>
import DrawScreen from "@/components/DrawScreen.vue";
import ColorSelect from "@/components/ColorSelect.vue";
import LocalChange from "@/components/LocalChange.vue";
import HelpDeurmaten from "@/components/help/HelpDeurmaten.vue";
import HelpDraaipunt from "@/components/help/HelpDraaipunt.vue";
import HelpDeurgrepen from "@/components/help/HelpDeurgrepen.vue";
import HelpOnderverdeling from "@/components/help/HelpOnderverdeling.vue";
import HelpTrekkerMetSlot from "@/components/help/HelpTrekkerMetSlot.vue";
import AlTools from "@/components/AlTools.vue";
import PrintScreen from "@/components/PrintScreen.vue";
import LoginScreen from "@/components/LoginScreen.vue";
import PriceCalcScreen from "@/components/PriceCalcScreen.vue";
import LogoScreen from "@/components/LogoScreen.vue";
export default {
  name: "HomeView",
  components: {
    DrawScreen,
    ColorSelect,
    HelpDeurmaten,
    HelpDraaipunt,
    HelpDeurgrepen,
    HelpOnderverdeling,
    HelpTrekkerMetSlot,
    LocalChange,
    AlTools,
    PrintScreen,
    LoginScreen,
    PriceCalcScreen,
    LogoScreen,
  },
  data() {
    return { qouteFormSubmitted: false };
  },
  computed: {
    klantRef: {
      get() {
        return this.$store.state.order.klantref;
      },
      set(value) {
        this.$store.commit("setKlantRef", value);
      },
    },
    deurgeheelRef: {
      get() {
        return this.$store.state.deurgeheelRef;
      },
      set(value) {
        this.$store.commit("setDeurgeheelRef", value);
      },
    },
    qoute_firstname: {
      get() {
        return this.$store.state.qouteForm.firstname;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "firstname",
          value: value,
        });
      },
    },
    qoute_lastname: {
      get() {
        return this.$store.state.qouteForm.lastname;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "lastname",
          value: value,
        });
      },
    },
    qoute_phone: {
      get() {
        return this.$store.state.qouteForm.phone;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "phone",
          value: value,
        });
      },
    },
    qoute_mail: {
      get() {
        return this.$store.state.qouteForm.mail;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "mail",
          value: value,
        });
      },
    },
    qoute_country: {
      get() {
        return this.$store.state.qouteForm.country;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "country",
          value: value,
        });
      },
    },
    qoute_street: {
      get() {
        return this.$store.state.qouteForm.street;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "street",
          value: value,
        });
      },
    },
    qoute_zip: {
      get() {
        return this.$store.state.qouteForm.zip;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "zip",
          value: value,
        });
      },
    },
    qoute_municipality: {
      get() {
        return this.$store.state.qouteForm.municipality;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "municipality",
          value: value,
        });
      },
    },
    qoute_dealer: {
      get() {
        return this.$store.state.qouteForm.dealer;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "dealer",
          value: value,
        });
      },
    },
    user_name: {
      get() {
        return this.$store.state.sec.user_name;
      },
      set(value) {
        this.$store.commit("setUserName", value);
      },
    },
    user_pwd: {
      get() {
        return this.$store.state.sec.user_pwd;
      },
      set(value) {
        this.$store.commit("setUserPwd", value);
      },
    },
    productType: {
      get() {
        return this.$store.state.productType;
      },
      set(value) {
        this.$store.commit("setproductType", value);
      },
    },
    aantalDeuren: {
      get() {
        return this.$store.state.aantalDeuren;
      },
      set(value) {
        this.$store.commit("setAantalDeuren", value);
      },
    },
    frameKeuze: {
      get() {
        if (this.$store.state.aantalDeuren >= 1) {
          return this.$store.state.aldv[this.$store.state.alustaDeurKeuze]
            .navisioncode;
        } else {
          return this.$store.state.ALF[this.$store.state.alustaDeurKeuze]
            .navisioncode;
        }
      },
    },
    draairichting: {
      get() {
        return this.$store.state.draairichting;
      },
      set(value) {
        this.$store.commit("setDraairichting", value);
      },
    },
    ALOkeuze: {
      get() {
        return this.$store.state.ALOkeuze;
      },
      set(value) {
        this.$store.commit("setALOkeuze", value);
      },
    },
    glassoortKeuze: {
      get() {
        return this.$store.state.glassoortKeuze;
      },
      set(value) {
        this.$store.commit("setGlassoortKeuze", value);
      },
    },
    hendelKeuze: {
      get() {
        return this.$store.state.hendelKeuze;
      },
      set(value) {
        if (value == 14) {
          this.middenslot = 1;
        } else {
          if (this.$store.state.hendelKeuze == 14 && value != 14) {
            this.middenslot = 0;
          }
        }
        this.$store.commit("setHendelKeuze", value);
        if (value != 0 && value < 9) {
          if (this.hendelPositieX == 0) this.hendelPositieX = 80;
        } else this.hendelPositieX = 0;
      },
    },
    greeplengte: {
      get() {
        return this.$store.state.greeplengte;
      },
      set(value) {
        this.$store.commit("setGreeplengte", value);
      },
    },
    hendelPositieX: {
      get() {
        return this.$store.state.hendelPositie.x;
      },
      set(value) {
        this.$store.commit("setHendelPositieX", value);
      },
    },
    hendelPositieY: {
      get() {
        return this.$store.state.hendelPositie.y;
      },
      set(value) {
        this.$store.commit("setHendelPositieY", value);
      },
    },
    slot: {
      get() {
        return this.$store.state.slot;
      },
      set(value) {
        this.$store.commit("setSlot", value);
      },
    },
    slotpot: {
      get() {
        return this.$store.state.slotpot;
      },
      set(value) {
        this.$store.commit("setSlotPot", value);
      },
    },
    cilinderkleur: {
      get() {
        return this.$store.state.cilinderkleur;
      },
      set(value) {
        this.$store.commit("setCilinderKleur", value);
      },
    },
    cilindertype: {
      get() {
        return this.$store.state.cilindertype;
      },
      set(value) {
        this.$store.commit("setCilinderType", value);
      },
    },
    middenslot: {
      get() {
        return this.$store.state.middenslot;
      },
      set(value) {
        this.$store.commit("setMiddenslot", value);
      },
    },
    slotplaat: {
      get() {
        return this.$store.state.slotplaat;
      },
      set(value) {
        this.$store.commit("setSlotplaat", value);
      },
    },
    slotX: {
      get() {
        return this.$store.state.slotX;
      },
      set(value) {
        this.$store.commit("setSlotX", value);
      },
    },
    kantschuifslot: {
      get() {
        return this.$store.state.kantschuifslot;
      },
      set(value) {
        this.$store.commit("setKantschuifslot", value);
      },
    },
    asymmetrisch: {
      get() {
        return this.$store.state.asymmetrisch;
      },
      set(value) {
        this.$store.commit("setAsymmetrisch", value);
        this.$store.commit(
          "setVerticaleOnderverdelingen",
          this.$store.state.verticaleOnderverdeling
        );
        this.$store.commit(
          "setHorizontaleOnderverdelingen",
          this.$store.state.horizontaleOnderverdeling
        );
      },
    },
    aantalPanelenLinks: {
      get() {
        return this.$store.state.aantalPanelenLinks;
      },
      set(value) {
        this.$store.commit("setAantalPanelenLinks", value);
      },
    },
    aantalPanelenRechts: {
      get() {
        return this.$store.state.aantalPanelenRechts;
      },
      set(value) {
        this.$store.commit("setAantalPanelenRechts", value);
      },
    },
    horizontaleOnderverdeling: {
      get() {
        return this.$store.state.horizontaleOnderverdeling;
      },
      set(value) {
        this.$store.commit("setHorizontaleOnderverdeling", value);
        this.$store.commit("setHorizontaleOnderverdelingen", value);
      },
    },
    verticaleOnderverdeling: {
      get() {
        return this.$store.state.verticaleOnderverdeling;
      },
      set(value) {
        this.$store.commit("setVerticaleOnderverdeling", value);
        this.$store.commit("setVerticaleOnderverdelingen", value);
      },
    },
    draaipuntX: {
      get() {
        return this.$store.state.draaipuntX;
      },
      set(value) {
        this.$store.commit("setDraaipuntX", value);
        this.$store.commit("calculateDraaipuntWarning");
      },
    },
    Bkader: {
      get() {
        return this.$store.state.kadermaat.Breedte;
      },
      set(value) {
        this.$store.commit("setBreedte", value);
        this.$store.commit("calculateX");
        this.$store.commit("calculateDraaipuntWarning");
        this.$store.commit("setGewicht");
        if (!this.$store.state.asymmetrisch) {
          this.$store.commit(
            "setVerticaleOnderverdelingen",
            this.$store.state.verticaleOnderverdeling
          );
        }
      },
    },
    Hkader: {
      get() {
        return this.$store.state.kadermaat.Hoogte;
      },
      set(value) {
        this.$store.commit("setHoogte", value);
        this.$store.commit("setGewicht");
        if (!this.$store.state.asymmetrisch) {
          this.$store.commit(
            "setHorizontaleOnderverdelingen",
            this.$store.state.horizontaleOnderverdeling
          );
        }
      },
    },
    Bglas: {
      get() {
        return this.$store.state.glasmaat.Breedte;
      },
      set(value) {
        this.$store.commit("setGlasBreedte", value);
      },
    },
    Hglas: {
      get() {
        return this.$store.state.glasmaat.Hoogte;
      },
      set(value) {
        this.$store.commit("setGlasHoogte", value);
      },
    },
    Token: {
      get() {
        return this.$store.state.token;
      },
      set(value) {
        this.$store.commit("setToken", value);
      },
    },
    opvulvlak: {
      get() {
        return this.$store.state.opvulvlak;
      },
      set(value) {
        this.$store.commit("setOpvulvlak", value);
      },
    },
    schuifdeurZijspeling: {
      get() {
        return this.$store.state.schuifdeurZijspeling;
      },
      set(value) {
        this.$store.commit("setSchuifdeurZijspeling", value);
      },
    },
  },
  mounted: function () {
    console.log(
      "%c Version:" + this.$store.state.version,
      "background: #222; color: #bada55"
    );
    if (this.$route.query.orderID) {
      this.$store.commit("setOrderID", this.$route.query.orderID);
    }
    if (this.$route.query.token) {
      this.Token = this.$route.query.token;
    } else {
      this.Token = "INVALID";
    }
    if (this.Token != "INVALID") {
      this.$store.commit("toggleShowPrice");
      this.$store.state.priceFix++;
    }
    if (this.$route.query.lang) {
      this.$i18n.locale = this.$route.query.lang;
    }
    this.Hkader = 2100;
    this.Bkader = 900;
    /*var self = this;
    window.addEventListener(
      "message",
      (event) => {
        if (event.origin == "https://egdesign.euroglas-dl.com") {
          console.log(event);
          //event.source.close();
          self.Token = event.data;
          if (self.Token == "INVALID") {
            alert(this.$t("ErrorAlert.InvalidToken"));
          }
          self.toEGDesign();
        }
      },
      false
    );*/
  },
  methods: {
    /*login() {
      var ref = window.open(
        "https://egdesign.euroglas-dl.com/egdesign/api/sso.php",
        "_blank"
      );
      setTimeout(function () {
        ref.postMessage(
          "Give me a token please!",
          "https://egdesign.euroglas-dl.com"
        );
      }, 500);
    },*/
    selectProductType(type, aantal) {
      this.productType = type;
      this.aantalDeuren = aantal;
    },
    selectAantalDeuren(aantal) {
      this.aantalDeuren = aantal;
    },
    selectDraairichting(richting) {
      this.draairichting = richting;
    },
    setAantalPanelenLinks(aantal) {
      this.aantalPanelenLinks = aantal;
    },
    setAantalPanelenRechts(aantal) {
      this.aantalPanelenRechts = aantal;
    },
    setAantalHorizontaleOnderverdeling(aantal) {
      this.horizontaleOnderverdeling = aantal;
    },
    setAantalVerticaleOnderverdeling(aantal) {
      this.verticaleOnderverdeling = aantal;
    },
    setHendelKeuze(index) {
      this.hendelKeuze = index;
    },
    setGlassoortKeuze(index) {
      this.glassoortKeuze = index;
    },
    setSlot(index) {
      this.slot = index;
    },
    setSlotPot(index) {
      this.slotpot = index;
    },
    setCilinderKleur(index) {
      this.cilinderkleur = index;
    },
    setCilinderType(index) {
      this.cilindertype = index;
    },
    setMiddenslot(index) {
      this.middenslot = index;
    },
    setSlotplaat(index) {
      this.slotplaat = index;
    },
    setKantschuifslot(index) {
      this.kantschuifslot = index;
    },
    setOpvulvlak(index) {
      this.opvulvlak = index;
    },
    setALOkeuze(keuze) {
      this.ALOkeuze = keuze;
      if (this.ALOkeuze == 1) {
        this.setOpvulvlak(0);
      }
    },
    updatePaneelLinks(e, index) {
      this.$store.commit("updatePaneelLinks", {
        id: index,
        value: e.target.value,
      });
    },
    updatePaneelRechts(e, index) {
      this.$store.commit("updatePaneelRechts", {
        id: index,
        value: e.target.value,
      });
    },
    updateHorizontaleOnderverdeling(e, index) {
      this.$store.commit("updateHorizontaleOnderverdeling", {
        id: index,
        value: e.target.value,
      });
    },
    updateVerticaleOnderverdeling(e, index) {
      this.$store.commit("updateVerticaleOnderverdeling", {
        id: index,
        value: e.target.value,
      });
    },
    checkForm() {
      const elementList = document.querySelectorAll(".is-invalid");
      if (
        elementList.length == 0 &&
        this.Bglas > 100 &&
        this.Bkader <= 1500 &&
        this.Hglas > 100 &&
        this.Hkader <= 3000 &&
        this.draaipuntX <= this.Bkader / 2 &&
        this.draaipuntX >= 110
      ) {
        return true;
      } else {
        return false;
      }
    },
    addWarnings() {
      if (this.$store.state.toonDraaipuntWaarschuwing)
        this.$store.commit("addHiddenRemark", this.$t("Draaipunt.Error01"));
    },
    async toEGDesign() {
      const self = this;
      const lang = this.$i18n.locale;
      if (
        (this.Token != "INVALID" ||
          (this.user_name != "" && this.user_pwd != "")) &&
        this.checkForm()
      ) {
        //add warnings etc...
        this.addWarnings();
        const theOrder = await this.$store.dispatch("generateOrderJson");
        if (theOrder != 0) {
          const id = await this.$store.dispatch("saveToEGDesign", theOrder);
          if (id != 0) {
            //if (confirm("Edit in EGDesign?") == true) {
            location.replace(
              "https://egdesign.euroglas-dl.com/egdesign/order.php?orderID=" +
                id +
                "&token=" +
                self.Token +
                "&lang=" +
                lang
            );
            //}
          } else {
            alert(this.$t("ErrorAlert.ErLiepIetsMis"));
          }
        }
      } else {
        if (this.Token != "INVALID") {
          alert(this.$t("ErrorAlert.VulAllesIn"));
        }
      }
    },
    async sendMail() {
      var form = document.getElementById("qouteForm");
      const lang = this.$i18n.locale;
      if (form.checkValidity() && this.checkForm()) {
        //add warnings etc...
        this.addWarnings();
        const theOrder = await this.$store.dispatch("generateOrderJson");
        if (theOrder != 0) {
          const id = await this.$store.dispatch(
            "saveToEGDesignAdmin",
            theOrder
          );
          if (id != 0) {
            await this.$store.dispatch("sendMail", lang);
            this.qouteFormSubmitted = true;
          } else {
            alert(this.$t("ErrorAlert.ErLiepIetsMis"));
          }
        }
      } else {
        alert(this.$t("ErrorAlert.VulAllesIn"));
      }
    },
    async exportOrder() {
      const elementList = document.querySelectorAll(".is-invalid");
      if (elementList.length == 0) {
        const theOrder = await this.$store.dispatch("generateOrderJson");
        if (theOrder != 0) {
          if (confirm("Download export file?") == true) {
            this.download("test.txt", JSON.stringify(theOrder));
          }
        } else {
          alert(this.$t("ErrorAlert.ErLiepIetsMis"));
        }
      } else {
        alert(this.$t("ErrorAlert.VulAllesIn"));
      }
    },
    backToEgdl() {
      location.replace(
        "https://egdesign.euroglas-dl.com/egdesign/start.php?token=" +
          this.Token +
          "&lang=" +
          this.$i18n.locale
      );
    },
    download(filename, textInput) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8, " + encodeURIComponent(textInput)
      );
      element.setAttribute("download", filename);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>
