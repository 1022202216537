export default {
  "Base": {
    "Links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
    "Rechts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechts"])},
    "Verticaal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verticaal"])},
    "Horizontaal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontaal"])},
    "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download visualisatie"])},
    "greeplengte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greeplengte"])}
  },
  "MainTitle": {
    "Deuren/panelen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deuren/panelen"])},
    "Kaderverdeling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaderverdelingen"])},
    "Deurgrepen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deurgrepen"])},
    "Glas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glas"])},
    "Extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
    "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleur en afwerking"])},
    "Pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijsberekening"])}
  },
  "SubTitle": {
    "Producttype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producttype"])},
    "Deurtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deurtype"])},
    "Draairichting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draairichting"])},
    "Deurmaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmetingen deur/paneel"])},
    "Panelen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal en afmetingen extra panelen"])},
    "Draaipunt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draaipuntafstand van de deur"])},
    "Aantal/positie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal/positie onderverdelingen"])},
    "Greeptype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greeptype"])},
    "Maten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmetingen"])},
    "Positie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positie van de greep"])},
    "MetMiddenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met middenslot"])},
    "Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slotplaat"])},
    "Glassoorten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glassoorten"])},
    "Deurslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deurslot"])},
    "SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slotbusje"])},
    "Cilinders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cilinder"])},
    "ALOType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profieltype"])},
    "Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opvulvlak"])},
    "Ralkleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL-kleur"])},
    "Lakafwerking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lak-afwerking"])},
    "Anodic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC"])},
    "Zijspeling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zijspeling"])},
    "AsTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOLS"])},
    "Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kantschuifslot"])}
  },
  "Producttype": {
    "Hydraulische": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydraulische draaideur"])},
    "Paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkel vast paneel"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deur- en paneelcombinaties kunnen naar wens worden geconfigureerd wanneer u verder gaat."])},
    "Sliding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schuifdeur"])}
  },
  "Deurtype": {
    "Enkel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkele deur"])},
    "Dubbel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dubbele deur"])}
  },
  "Deurmaten": {
    "Hoogte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deur/paneel hoogte"])},
    "Breedte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deur/paneel breedte"])},
    "Info01": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["De spanmaten bij bovenstaande deurmaten bedragen: ", _interpolate(_list(0)), " mm x ", _interpolate(_list(1)), " mm."])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De hoogte is maximaal 3000 mm!"])},
    "Error02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De breedte is maximaal 1500 mm!"])},
    "Error03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het gewicht van de deur mag max. 100kg bedragen. Verklein de afmetingen van de deur aub."])}
  },
  "AsTools": {
    "TextTool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangezien uw deurbreedte groter dan 1010 mm is, wordt er aangeraden om ASTOOL1 en ASTOOL2 aan te schaffen om de eind- en sluitsnelheid en bovenspil in te stellen."])},
    "TextToolXL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangezien uw deurbreedte groter dan 1200 mm is, wordt er aangeraden om ASTOOL1XL en ASTOOL2XL aan te schaffen om de eind- en sluitsnelheid en bovenspil in te stellen."])},
    "AsTool1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 1 - L: 300mm"])},
    "AsTool2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 2 - L: 300mm"])},
    "AsTool1XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 1XL - L: 600mm"])},
    "AsTool2XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 2XL - L: 600mm"])}
  },
  "Panelen": {
    "PaneelNum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Paneel ", _interpolate(_list(0)), " (mm)"])},
    "AfwijkendeSituatie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afwijkende situaties"])},
    "AfwijkendeSituatieLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://alusta.be/assets/pdf/afwijkende_panelen.pdf"])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De breedte is maximaal 1500 mm!"])},
    "Asymmetrisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asymmetrisch"])},
    "TProfiel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T-Profiel"])},
    "IProfiel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I-Profiel"])},
    "ProfielInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien u een AMUR SQ of AMT trekgreep selecteert en deze op een profiel positioneert, wordt er aangeraden om voor een I-profiel te kiezen. Een T-profiel is technisch ook mogelijk, maar is een duurdere optie en minder gebruiksvriendelijk door de opstaande rand."])}
  },
  "Draaipunt": {
    "Afstand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afstand"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De vooropgestelde afstand is de meest aanbevolen keuze bij bovenstaande deurmaten."])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We raden aan om het draaipunt meer te verplaatsen. De gekozen positie valt buiten garantie."])},
    "Error02": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Het draaipunt kan niet meer dan de helft van de deurbreedte zijn (", _interpolate(_list(0)), " mm)!"])},
    "Error03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het draaipunt kan niet kleiner zijn dan 110 mm!"])}
  },
  "Zijspeling": {
    "Afstand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speling tussen muur & Alusta schuifdeur"])}
  },
  "Greeptype": {
    "Hoekgreep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoekgreep"])},
    "AMURSQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ trekgreep"])},
    "AMT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT trekgreep"])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze greep is te groot voor deze deur."])}
  },
  "Maten": {
    "MetMiddenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoekgreep met middenslot"])}
  },
  "Glassoort": {
    "Klaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar (10 mm)"])},
    "Grijs10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grijs (10 mm)"])},
    "Brons10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brons (10 mm)"])},
    "AcideKlaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acide Klaar (10 mm)"])},
    "AcideExtraKlaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acide Extra Klaar (10 mm)"])},
    "Kathedraal8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kathedral (8 mm)"])},
    "Raywall8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raywall (8 mm)"])}
  },
  "Slot": {
    "SlotX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot X (mm)"])},
    "0Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zonder slot"])},
    "1Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met slot"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het slot bevindt zich in het onderste profiel. Om de deur open en vast te draaien zal u zich dienen te bukken."])},
    "0SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zonder slotbusje"])},
    "1SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met slotbusje"])},
    "0CilinderKleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwart geanodiseerd"])},
    "1CilinderKleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chrome gepolijst"])},
    "0CilinderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelijksluitend"])},
    "1CilinderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkelsluitend"])},
    "0Middenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trekgreep zonder middenslot"])},
    "1Middenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trekgreep met middenslot"])},
    "0Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zonder slotplaat"])},
    "1Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met slotplaat"])},
    "0Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen kantschuifslot"])},
    "1Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kantschuifslot mat zwart"])},
    "2Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kantschuifslot alu mat"])}
  },
  "Opvulvlak": {
    "0Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen opvulvlak"])},
    "1Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderaan"])},
    "2Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In het midden"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het opvulvlak bevindt zich onderaan de deur tot aan de eerste horizontale onderverdeling."])}
  },
  "Knoppen": {
    "Opslaan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan naar EGDesign"])},
    "Terug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar EGDesign zonder opslaan"])},
    "Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenden"])},
    "MailVerdouw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen"])}
  },
  "ErrorAlert": {
    "ErLiepIetsMis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er liep iets mis!"])},
    "VulAllesIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul alles correct in!"])},
    "InvalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foutieve sleutel"])}
  },
  "HelpDeurgrepen": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De horizontale postie (h) van de deurgreep wordt bepaald van de centerlijn van de deurgreep tot de uiterste rechtse rand van de deurkader."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De verticale positie (v) van de deurgreep wordt bepaald van het center van de deurgreep tot de onderste rand van de deurkader."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien u de positie van de hoekgreep wenst zoals op de visualisatie hiernaast dient u bij de verticale positie volgende waarde in te vullen: ½ x lengte hoekgreep + 68"])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien u de positie van de hoekgreep wenst zoals op de visualisatie hiernaast dient u bij de verticale positie volgende waarde in te vullen: ½ x lengte hoekgreep"])}
  },
  "HelpDeurmaten": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogte deur = hoogte vast paneel."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hou rekening met volgende aanbevolen spelingen:"])},
    "Deur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deur"])},
    "vloer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vloer"])},
    "plafond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plafond"])},
    "muur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["muur"])},
    "deur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deur"])},
    "paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paneel"])},
    "Paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paneel"])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De spanmaten zijn de kleinste hoogte- en breedte maten opgemeten in de opening waar de deur(en)/pane(e)l(en) dienen te komen."])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij een dubbele deur zal de ingegeven deurbreedte verdubbeld worden."])},
    "RekenvoorbeeldLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://alusta.be/assets/pdf/rekenvb_config_nl.pdf"])},
    "Rekenvoorbeeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download rekenvoorbeeld"])}
  },
  "HelpAsTools": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij deurbreedtes 1010 mm < x ≤ 1200 mm wordt er aangeraden om ASTOOL1 te bestellen om de eind- en sluitsnelheid in te stellen indien u geen platte kop schroevendraaier met lengte 300 mm heeft."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij deurbreedtes 1010 mm < x ≤ 1200 mm wordt er aangeraden om ASTOOL2 te bestellen om de bovenspil in te stellen indien u geen zeskant maat 3 met lengte 300 mm heeft."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij deurbreedtes 1200 mm < x ≤ 1500 mm wordt er aangeraden om ASTOOL1XL te bestellen om de eind- en sluitsnelheid in te stellen indien u geen platte kop schroevendraaier met lengte 600 mm heeft."])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij deurbreedtes 1200 mm < x ≤ 1500 mm wordt er aangeraden om ASTOOL2XL te bestellen om de bovenspil in te stellen indien u geen zeskant maat 3 met lengte 600 mm heeft."])}
  },
  "HelpDraaipunt": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De draaipuntafstand (a) is de afstand van het center van de bodemtaats tot de uiterste rand van de deurkader. Wanneer u deze configuratie bewaart in EGDesign zal op de tekening de waarde a – 19 mm verschijnen omdat hier de draaipuntafstand (a) de afstand is van het center van de bodemtaats tot de uiterste rand van het glas."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omwille van de structurele stevigheid raden wij aan om de vooropgestelde draaipuntafstand niet te verplaatsen."])}
  },
  "HelpOnderverdeling": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De positie van de verticale glaslat wordt bepaald door de afstand tussen de centerlijn van de glaslat en de uiterste links of rechtse rand van de deurkader afhankelijk van de gekozen draairichting. Wanneer u deze configuratie bewaart in EGDesign zal op de tekening de waarde v - 19 mm verschijnen omdat hier de afstand bepaald wordt van de centerlijn van de glaslat tot de uiterste rand van het glas."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De positie van een horizontale glaslat wordt bepaald door de afstand tussen de centerlijn van een glaslat en de onderste rand van de deurkader. Wanneer u deze configuratie bewaart in EGDesign zal op de tekening de waarde h - 66 mm verschijnen omdat hier de afstand bepaald wordt van de centerlijn van de glaslat tot de onderste rand van het glas."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard worden de horizontale en verticale onderverdelingen symmetrisch geplaatst t.o.v. het glas. De verschillende glasoppervlakken tussen de onderverdelingen zijn hierdoor even groot. (A=A=A) (B=B=B) Bij een asymmetrische onderverdeling wordt de positie bepaald t.o.v. de deurkader. Zie hieronder:"])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien u de deurgreep in het midden wenst van een horizontale onderverdeling, kan u dit eenvoudig aanpassen in EGDesign."])}
  },
  "HelpTrekkermetslot": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het middenslot wordt in combinatie met een AMURSQ/AMT trekgreep in het center van de deurtrekker gepositioneerd. Neem contact op met uw dealer indien u het middenslot verticaal anders wenst te positioneren."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het middenslot wordt in combinatie met een AMURSQ/AMT trekgreep in het center van de deurtrekker gepositioneerd. In EGDesign kan u het middenslot verticaal anders positioneren t.o.v. de positie van de deurtrekker."])}
    }
  },
  "HelpRalkleur": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL-kleur 9005 in combinatie met structuurlak is de standaard steellook afwerking en is steeds voorradig, tenzij stockbreuk."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staat je beoogde RAL-kleur niet in de configurator? Vraag na bij jouw dealer welke RAL-kleuren allemaal mogelijk zijn."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een digitale weergave van kleuren is nooit waarheidsgetrouw. Vraag daarom aan jouw dealer om stalen te bekijken van de gewenste kleur- en afwerking."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wens je om bepaalde accessoires een andere kleur- en afwerking te geven? Dit kan! Vraag na bij jouw dealer."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL-kleur 9005 in combinatie met structuurlak is de standaard steellook afwerking en is steeds voorradig, tenzij stockbreuk."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staat de gewenste RAL-kleur niet in de configurator? Via EGDesign is het mogelijk om andere RAL-kleuren te selecteren."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een digitale weergave van kleuren is nooit waarheidsgetrouw. Bied jouw klant altijd stalen aan."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via EGDesign is het mogelijk om accessoires een andere kleur- en afwerking toe te kennen."])}
    },
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hou er rekening mee dat het kiezen van een andere kleur en afwerking de levertermijn sterk kan doen toenemen."])}
  },
  "HelpLakafwerking": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij het kiezen van een RAL-kleur is het noodzakelijk om een gewenste lak-afwerking (structuur, mat, glanzend) te kiezen."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om het steellook-effect te realiseren, wordt structuurlak aanbevolen. Vraag bij jouw dealer stalen om de verschillende afwerkingen te vergelijken."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij het kiezen van een RAL-kleur is het noodzakelijk om een gewenste lak-afwerking (structuur, mat, glanzend) te kiezen."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om het steellook-effect te realiseren, wordt structuurlak aanbevolen. Bied jouw klant stalen aan van de verschillende lak-afwerkingen."])}
    }
  },
  "HelpAnodic": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De geanodiseerde collectie geeft een geanodiseerd effect en gaat perfect samen met het Alusta productgamma."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij de geanodiseerde collectie is het niet mogelijk om een lak-afwerking te kiezen."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een digitale weergave van kleuren is nooit waarheidsgetrouw. Vraag daarom aan jouw dealer om stalen te bekijken van de gewenste kleur- en afwerking."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De geanodiseerde collectie geeft een geanodiseerd effect en gaat perfect samen met het Alusta productgamma."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij de geanodiseerde collectie is het niet mogelijk om een lak-afwerking te kiezen."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een digitale weergave van kleuren is nooit waarheidsgetrouw. Bied jouw klant altijd stalen aan."])}
    }
  },
  "OfferteFormulier": {
    "MainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacteer ons"])},
    "MainTitleVerdouw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantgegevens"])},
    "SubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vragen over deze Alusta-configuratie?"])},
    "voornaam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "naam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familienaam"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "straat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straat + nr"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "gemeente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeente"])},
    "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "welkeDealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via welke dealer heeft u ons gevonden?"])},
    "bedankt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hartelijk dank voor uw aanvraag. Wij contacteren u zo spoedig mogelijk!"])}
  },
  "SelecteerKleur": {
    "structuur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structuur"])},
    "mat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mat"])},
    "glanzend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glanzend"])}
  },
  "ColorList": {
    "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9005 structuur Gitzwart"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere kleur of afwerking"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9005 Gitzwart"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9016 Verkeerswit"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9010 Zuiver wit"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 7016 Antracietgrijs"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 7021 Zwartgrijs"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Natura"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Saphire"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Malachite"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Champagne"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Gold"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Bronze"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Brown"])}
  },
  "Error": {
    "glasstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "Referenties": {
    "MainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orderreferenties"])},
    "KlantRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw orderreferentie"])},
    "Referentie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw referentie van de orderregel"])}
  },
  "Pricing": {
    "btw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijsberekening exclusief btw en plaatsingskosten"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotaal"])},
    "frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alustakit (deur/paneel)"])},
    "lak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laksupplement"])},
    "horizontalDividers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontale onderverdelingen"])},
    "verticalDividers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verticale onderverdelingen"])},
    "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opvulvlak"])},
    "AMT200300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT trekgreep 300mm"])},
    "AMT200500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT trekgreep 500mm"])},
    "AMT200700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT trekgreep 700mm"])},
    "AMT201200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT trekgreep 1200mm"])},
    "AMUR200300SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ trekgreep 300mm"])},
    "AMUR200500SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ trekgreep 500mm"])},
    "AMUR201200SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ trekgreep 1200mm"])},
    "AMUR302000SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ trekgreep 2000mm"])},
    "ASCS01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASCS01 inbouw cilinderslot excl cilinder"])},
    "ASHG01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG01 hoekgreep 150mm"])},
    "ASHG1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG1200 hoekgreep 1200mm"])},
    "ASHG2000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG2000 hoekgreep 2000mm"])},
    "ASHG300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG300 hoekgreep 300mm"])},
    "ASHG500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG500 hoekgreep 500mm"])},
    "ASHGMAAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHGMAAT hoekgreep op maat"])},
    "ASTOOL1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL1 Alusta gereedschap 1"])},
    "ASTOOL1XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL1XL Alusta gereedschap 1XL"])},
    "ASTOOL2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL2 Alusta gereedschap 2"])},
    "ASTOOL2XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL2XL Alusta gereedschap 2XL"])},
    "V515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V515 slotbusje"])},
    "V017S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V017S gelijksluitende cilinder inclusief 3 sleutels"])},
    "V017D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V017D niet-gelijksluitende cilinder inclusief 3 sleutels"])},
    "AS522HG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS522HG hoekgreep 150mm met middenslot"])},
    "AS522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS522 middenslot"])},
    "V532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V532 slotplaat"])},
    "061_7168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["061.7168 kantschuifslot"])}
  }
}